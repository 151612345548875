import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Web 技術でレガシーな医療業界に革命を！（MedNightTokyo#2 レポート）",
  "date": "2017-04-21T01:43:35.000Z",
  "slug": "entry/2017/04/21/104335",
  "tags": ["medley"],
  "hero": "./2017_04_21.png",
  "heroAlt": "mednight"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`オンライン病気事典 MEDLEY`}</a>{`の開発をしています徐です。`}</p>
    <p>{`2017 年 4 月 19 日（水）に`}<a parentName="p" {...{
        "href": "https://binc.jp/"
      }}>{`BASE 株式会社`}</a>{`さんの会議室にて、エムスリー株式会社さんとの合同勉強会(`}<a parentName="p" {...{
        "href": "https://mednight.connpass.com/"
      }}>{`MedNightTokyo`}</a>{`)を開催しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170420/20170420165035.png",
        "alt": "f:id:yamadagenki:20170420165035p:plain",
        "title": "f:id:yamadagenki:20170420165035p:plain"
      }}></img></p>
    <p>{`BASE 株式会社さんのオフィスはとてもきれいでした！`}</p>
    <p>{`「Web 技術でレガシーな医療業界に革命を！エンジニア開発裏話」というテーマで、弊社からは`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/42315"
      }}>{`平木`}</a>{`と`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/57252"
      }}>{`田中`}</a>{`が`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`オンライン診療アプリ CLINICS`}</a>{`の開発の舞台裏について発表しました。 発表内容について一部ご紹介致します。`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%AF%A5%E9%A5%A6%A5%C9"
      }}>{`クラウド`}</a><a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C5%C5%BB%D2%A5%AB%A5%EB%A5%C6"
      }}>{`電子カルテ`}</a>{`を支える魂の技術 (エムスリー株式会社 冨岡 純さん)`}</h1>
    <p>{`トップバッターは、エムスリーの冨岡純さんによる`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%AF%A5%E9%A5%A6%A5%C9"
      }}>{`クラウド`}</a><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C5%C5%BB%D2%A5%AB%A5%EB%A5%C6"
      }}>{`電子カルテ`}</a>{`についての発表でした。`}</p>
    <p>{`“カルテ"というと、いかにも"医療"という感じがしますが、これを電子化し、しかも`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%AF%A5%E9%A5%A6%A5%C9"
      }}>{`クラウド`}</a>{`化し、
今現在病院で使われるシステムと連携する…などと考えると難しいことが大量にあるそうです。
まさに、"レガシー"とどう立ち向かったか、というのがわかるお話でした。`}</p>
    <p>{`※ 発表資料は下記`}</p>
    <iframe id="talk_frame_388168" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/cca6df720b814ee9949c1aa1c9715d4d" width="710" height="463" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/jooohn/kuraudodian-zi-karutewozhi-eruhun-falseji-shu">speakerdeck.com</a></cite>
    <h1>{`CLINICS を支える技術と開発体制 (株式会社メドレー 田中清)`}</h1>
    <p>{`田中の発表は、`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`で用いられている技術と開発体制でした。
メドレーの中でもベテランエンジニアが集まる CLINICS は技術的にもレベルが高く、
また開発体制も非エンジニアも多く関わるプロダクトならではの工夫をしており、
「ベテラン(おじさん)たちの経験と知恵が詰まってるな」と深く関心してしまいました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170420/20170420164547.jpg",
        "alt": "f:id:yamadagenki:20170420164547j:plain",
        "title": "f:id:yamadagenki:20170420164547j:plain"
      }}></img></p>
    <p>{`※ 発表資料は下記`}</p>
    <iframe id="talk_frame_388121" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/632af2c7295548cd89c8b96043747277" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/clinicsji-shu-gou-cheng-tokai-fa-ti-zhi">speakerdeck.com</a></cite>
    <h1>{`非定型なレポート依頼と`}<a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/Python"
      }}>{`Python`}</a>{`で戦う (エムスリー株式会社 三浦 琢磨さん)`}</h1>
    <p>{`「医師免許を持つエンジニア」という変わった経歴をもつ三浦琢磨さんからは、
ビジネスに於ける課題をエンジニアリング(`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Python"
      }}>{`Python`}</a>{`)の力でいかに効率化したか、というお話でした。`}</p>
    <p>{`業務で繰り返される作業を課題として捉え、それを着実にエンジニアリングの力で解決していく姿勢は、
IT 企業ならではと言った印象を受け、「こういう仕事いいなぁ」という気持ちになりました。`}</p>
    <p>{`※ 便利なライブラリーをそのうち公開していただけるそうで、ちょっと期待。`}</p>
    <iframe style={{
      "border": "1px solid #CCC",
      "borderWidth": "1px",
      "marginBottom": "5px",
      "maxWidth": "100%"
    }} src="https://www.slideshare.net/slideshow/embed_code/key/g2lhurItopt713" width="427" height="356" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" allowFullScreen=""></iframe>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.slideshare.net/takumamiura3/python-75184158",
          "title": "非定型なレポート依頼と Python で戦う"
        }}>{`非定型なレポート依頼と Python で戦う`}</a></strong>{` from `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.slideshare.net/takumamiura3"
        }}>{`琢磨 三浦`}</a></strong></p>
    <cite className="hatena-citation"><a href="https://www.slideshare.net/takumamiura3/python-75184158">www.slideshare.net</a></cite>
    <h1>{`CLINICS の変化に耐える Mithril (株式会社メドレー 平木聡)`}</h1>
    <p>{`平木からは、`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`で使われている Mithril について紹介しました。
CLINICS はサービスの UI デザインだけではなく、
内部でもライブラリーのバージョンやファイル構成など変化を繰り返しており
そういった変化にどう耐えて来たのか、という CLINICS の歴史も交え発表しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170420/20170420164628.jpg",
        "alt": "f:id:yamadagenki:20170420164628j:plain",
        "title": "f:id:yamadagenki:20170420164628j:plain"
      }}></img></p>
    <p>{`※ 発表資料は下記`}</p>
    <iframe id="talk_frame_388133" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/f4202e29617940539cfc73b1a275b9f8" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/clinicsfalsebian-hua-ninai-erumithril">speakerdeck.com</a></cite>
    <h1>{`まとめ`}</h1>
    <p>{`第 2 回目となる MedNightTokyo の勉強会を行いました。`}</p>
    <p>{`発表内容もさることながら、真剣に発表する発表者の様子からは、
IT の力を用いてレガシーな医療業界全体をより良くしていこうという熱い思いが感じられました。`}</p>
    <p>{`こうした熱い思いを持ったエンジニアたちが集まる、この"医療"という分野で、
技術的にも切磋琢磨できるのは本当にやりがいがある仕事なのだと再認識しました。`}</p>
    <p>{`来月 5/17(水)には、株式会社トレタとの合同エンジニア＆デザイナーイベントを開催予定です！
弊社からは CTO の平山と、デザイナーの前田が登壇します。
ご興味ある方、ぜひご参加ください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="5/17 メドレー×トレタ合同エンジニア・デザイナーイベントを開催します！ by 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fprojects%2F97348" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/projects/97348">www.wantedly.com</a></cite>
    <p>{`また、このメドレーオフィシャルブログでも、勉強会情報を掲載していくので、ブログも引き続きチェックよろしくお願い致します。`}</p>
    <p>{`※`}<a parentName="p" {...{
        "href": "https://www.facebook.com/medley.jp/"
      }}>{`メドレー公式 Facebook ページ`}</a>{`に「いいね！」していただけると、ブログの最新情報をフォローできます!!`}</p>
    <h1>{`最後に`}</h1>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。`}</p>
    <p>{`皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      